import React, { useContext, useCallback } from "react";
import { StaticQuery, graphql } from "gatsby";
import { GlobalContext } from "../providers/GlobalProvider";

//styling
import "../css/clients.css"

const Clients = () => {
  const { setCursor } = useContext(GlobalContext);

  const toggleCursor = useCallback(() => {
      setCursor(({ active }) => ({ active: !active }));
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query MyClientsQuery {
          logos: allContentfulMedia(
            filter: { page: { eq: "home" }, section: { eq: "clients" } }
            sort: { fields: order }
          ) {
            nodes {
              description
              slug
              media {
                fluid(maxHeight: 224, maxWidth: 594){
                  src
                }
              }
              order
            }
          }

          header: contentfulComponent(slug: { eq: "clients-header" }) {
            headline
            slug
            decorator
          }
        }
      `}
      render={(data) => (
        <div className="clients-sec main-wrapper" key={data.header.slug}>
          <h3 className="decorator" >{data.header.decorator}</h3>
          <h1 className="headline dark" >
            {data.header.headline}
          </h1>
          <ul
            className="clients-table"
          >
            {data.logos.nodes.map((item, index) => (
                <li key={item.slug} id={item.slug} className="link has-animation" data-delay="10">
                  <img
                    src={item.media.fluid.src}
                    alt={item.description}
                    className="client-img"
                    onMouseEnter={toggleCursor}
                    onMouseLeave={toggleCursor}
                  />
                </li>
            ))}
          </ul>
        </div>

      )}
    />
  );
};

export default Clients;
