import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { contentfulComponent } = useStaticQuery(
    graphql`
      query MyPortfolioHeaderQuery {
        contentfulComponent(slug: {eq: "projects-header"}) {
          headline
          decorator
          subhead
        }
      }
    `
  );
  return contentfulComponent;
};
