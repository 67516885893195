import React, { useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"


const ServicesImg = ({ hovered }) => {

  useEffect(() => {
    if(typeof window !== 'undefined') checkIOS()
  }, [])

  function checkIOS() {
    let isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.userAgent === "MacIntel" && navigator.maxTouchPoints > 1);

    if (isIOS) {
      window.$("video.service").each(function () {
        window.$(this).attr("playsinline", "");
      });
    }
  }

  return (
    <div className="service-img-wrapper">
        <StaticQuery
        query={graphql`
        query ServicesMedia {
          allContentfulAccordion(sort: {fields: order}) {
            nodes {
              id
              slug
              media {
                file {
                  url
                }
              }
            }
          }
        }
        `}
        render={data => (
        <div className="has-animation" data-delay="10">
            {data.allContentfulAccordion.nodes.map((item, index) => (
              <video autoPlay loop muted
                id={item.slug}
                key={item.slug}
                alt={item.slug}
                className={`service ${hovered === index ? "" : "d-none"}`}
              >
                <source 
                  src={item.media.file.url}
                  type="video/mp4"></source>
              </video>
            ))}
        </div>
        )}
        />
    </div>
  )
}

export default ServicesImg;