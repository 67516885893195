import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { GlobalContext } from "../providers/GlobalProvider";
import { StaticQuery, graphql } from "gatsby";
import '../css/accordion.css';


const Accordion = (props) => {
  const { setCursor } = useContext(GlobalContext);
  const { handleMouseEnter } = props;
  const [clicked, setClicked] = useState(false);
  const accordion = useRef([]);

  function format(text) {
      if (text.indexOf("1/10") > -1) {
        let frac = text.split("1/10");
        return (
          <div>
            <sup className="frac">1</sup>&frasl;<span className="frac">10</span>{" "}
            {frac[1]}{" "}
          </div>
        );
      } else return text;
  }

  useEffect(() => {
    window.$("dd.accordion-content").slideUp(1).addClass("hide");
  }, []);

  const toggleAccordion = index => {
    if (clicked === index) {
      setClicked('');
      slideUp(accordion.current[index]);
    } else {
      setClicked(index);
      slideDown(accordion.current[index]);
    }
  };

  const slideDown = (element) => {
    window.$(element).next()
      .slideDown(350)
      .parent()
      .siblings("div")
      .children("dd.accordion-content")
      .slideUp(350)
      .prev()
      .removeClass("accordion-active")
  }

  const slideUp = (element) => {
    window.$(element)
      .removeClass("accordion-active")
      .siblings("dd.accordion-content")
      .slideUp(350);
  }

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  }, []);

  return (
      <StaticQuery
        query={graphql`
          query AccordionItemsQuery {
            allContentfulAccordion(sort: { fields: order }) {
              nodes {
                slug
                title
                description {
                  description
                }
              }
            }
          }
        `}
        render={(data) => (
          <div className="accordion-sec">
            <dl className="accordion">
              {data.allContentfulAccordion.nodes.map((item, index) => (
                <div 
                  key={item.slug}
                  onClick={() => toggleAccordion(index)}
                  onKeyDown={() => toggleAccordion(index)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  role="presentation"
                  >
                  <dt 
                    className={clicked === index ? 'accordion-active': null}
                    id={item.slug}
                    ref={el => accordion.current[index] = el} 
                  >
                    <span 
                      className="link"
                      onMouseEnter={toggleCursor}
                      onMouseLeave={toggleCursor}
                    >
                      {item.title}
                    </span>
                    <div className="acc-button-icon">
                      <i className="fa fa-chevron-down"></i>
                    </div>
                  </dt>
                    <dd className="accordion-content">
                      {item.description
                        ? format(item.description.description)
                        : ""}
                    </dd>
                </div>
              ))}
            </dl>
          </div>
        )}
      />
    );
}

export default Accordion
