import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { contentfulComponent } = useStaticQuery(
    graphql`
    query AgencyText {
        contentfulComponent(slug: {eq: "agency-text"}) {
          headline
          decorator
        }
      }
    `
  );
  return contentfulComponent;
};
