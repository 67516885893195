import React, { useContext, useCallback } from "react";
import { GlobalContext } from "../providers/GlobalProvider";
import Button from "../components/Button";
import { useSiteMetadata } from "../hooks/agency-header-metadata";
import { StaticQuery, graphql } from "gatsby";
import '../css/office.css';

const Office = () => {
  const { headline, decorator } = useSiteMetadata();
  const { setCursor } = useContext(GlobalContext);

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  }, []);

  return (
    <div>
      <div className="office-sec">
          <div className="agency-title">
            <h3 className="decorator">{decorator}</h3>
            <h1 className="headline dark">{headline}</h1>
          </div>
        <StaticQuery
          query={graphql`
              query AgencyPhotos {
                allContentfulMedia(filter: {section: {eq: "agency"}}, sort: {fields: order}) {
                  nodes {
                    slug
                    media {
                      fluid(maxHeight: 722, maxWidth: 474) {
                        src
                      }
                    }
                  }
                }
              }
              `}
          render={data => (
            <div className="img-set-wrapper">
              <div className="img-set has-animation" data-delay="10">
                {data.allContentfulMedia.nodes.map((item, index) => (
                    <div key={item.slug} id={item.slug} className="link img-wpr">
                      <img
                        src={item.media.fluid.src}
                        alt={item.slug}
                        className="item-image grid__item-img"
                        onMouseEnter={toggleCursor}
                        onMouseLeave={toggleCursor}
                      />
                    </div>
                ))}
              </div>
            </div>
          )}
        />

          <div className="btn-wpr">
            <Button text="Meet the Team" bgColor="#6765f8" color="#fff" ajax="true" url="/team/" />
          </div>
      </div>
    </div>
  );
};

export default Office;
