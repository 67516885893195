import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
    const { contentfulComponent } = useStaticQuery(
        graphql`
      query HeroVideoQuery {
        contentfulComponent(slug: { eq: "homepage-hero" }) {
          slug
          asset {
            file {
              url
            }
          }
        }
      }
    `
    );
    return contentfulComponent;
};
