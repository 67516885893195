import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby"
import Accordion from "./Accordion"
import ServicesImg from "./ServicesImg"
import "../css/services.css";

const Services = () => {
  const [hovered, setHovered] = useState(3);

  const handleMouseEnter = index => {
    setHovered(index);
  }

  return (
    <StaticQuery
      query={graphql`
      query ServicesHeader {
        allContentfulComponent(filter: {slug: {eq: "services-header"}}) {
          nodes {
            decorator
            headline
            slug
          }
        }
      }
      `}

      render={data => (
        <div>
            {data.allContentfulComponent.nodes.map((info) => (
            <div key={info.slug} className="services-container main-wrapper">
                <div className="flex-grid my-accordion">
                    <div className="col has-animation">
                        <div className="services-text">
                          <h2 className="decorator">
                              {info.decorator}
                          </h2>
                          <h1 className="headline secondary dark">
                              {info.headline}
                          </h1>
                        </div>
                        <ServicesImg
                          hovered={hovered} />
                    </div>
                    <div className="col">
                      <Accordion 
                        hovered={hovered}
                        setHovered={setHovered}
                        handleMouseEnter={handleMouseEnter} />
                    </div>
                </div>
            </div>
            ))}
        </div>
      )}
    />
  )
}

export default Services;
