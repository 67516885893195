import React, { useContext, useEffect } from "react";
import Layout from "../components/Layout";
import { GlobalContext } from "../providers/GlobalProvider";

import "../styles/style.css";
import "../css/showcase.css";
import "../css/shortcodes.css";
import "../css/assets.css";
import "../styles/custom.css";

import Hero from "../components/Hero";
import Services from "../components/Services";
import Clients from "../components/Clients";
import Expertise from "../components/Expertise";
import Portfolio from "../components/Portfolio";
import Office from "../components/Office";

export default function Home() {
  const { setPageScrolled, setPageScrollUp } = useContext(GlobalContext);

  useEffect(() => {
    setPageScrolled(false);
    setPageScrollUp(false);
  }, []);

  return (
    <Layout bgColor='#f8fafb'>
      <div id="content-scroll">
        <div id="main" className="homepage">
          <Hero />
          <Services />
          <Clients />
          <Expertise />
          <Portfolio />
          <Office />
        </div>
      </div>
    </Layout>
  );
}
