import React, { useContext, useCallback, useEffect, useRef, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { GlobalContext } from "../providers/GlobalProvider";
import { useSiteMetadata } from "../hooks/portfolio-metadata";

//animations
import { gsap, Power3, Linear } from "gsap";

import "../css/portfolio.css";

const Portfolio = () => {
  const { decorator, headline, subhead } = useSiteMetadata();
  const { setCursor } = useContext(GlobalContext);

  const [offsetY, setOffsetY] = useState(0);
  const parallaxHandler = () => setOffsetY(window.pageYOffset);

  const portfolioItem = useRef([]);
  useEffect(() => {
    window.addEventListener("scroll", parallaxHandler);
    ScrollEffects();
    ParallaxScroll();

    return () => window.removeEventListener("scroll", parallaxHandler);
  }, []);

  const ScrollEffects = () => {
    var controller = new window.ScrollMagic.Controller();
    var $elheight = window.innerHeight;

    // portfolioItem.current.forEach((ele) => {
    //   var $thisHeight = ele.clientHeight;

    //   var scene = new window.ScrollMagic.Scene({
    //     triggerElement: ele,
    //     duration: $thisHeight,
    //   }).addTo(controller);

    //   scene.triggerHook(0.9);

    //   scene.on("enter", function () {
    //     ele.classList.add("active");
    //   });
    // });

    window.$(".portfolio .item").each(function () {
      var $this = window.$(this);
      var $thisHeight = window.$(this).height();
  
      var scene = new window.ScrollMagic.Scene({
        triggerElement: $this[0],
        duration: $thisHeight,
      }).addTo(controller);
  
      scene.triggerHook(0.9);
  
      scene.on("enter", function () {
        $this.addClass("active");
      });

    });
    

    window.$(".item").each(function () {
      var $this = window.$(this);
      var $thisheight = window.$(this).height() + $elheight;
      var bg = $this.find(".item-parallax.enabled");
  
      var parallax = gsap.fromTo(
        bg,
        { y: "20%" },
        { duration: 1, y: "-20%", ease: Linear.easeNone }
      );
  
      var parallaxScene = new window.ScrollMagic.Scene({
        triggerElement: this, // <-- Use this to select current element
        triggerHook: 1,
        duration: $thisheight,
      })
        .setTween(parallax)
        .addTo(controller);

    });


    window.$(".content-carousel").each(function () {
      var $this = window.$(this);
      var $thisHeight = window.$(this).outerHeight(true);
      var tlCarousel = gsap.timeline();
      tlCarousel.set(window.$(".content-carousel .swiper-slide"), {
        x: 800,
        opacity: 0,
      });
  
      var scene = new window.ScrollMagic.Scene({
        triggerElement: this,
        duration: $thisHeight,
      }).addTo(controller);
  
      scene.triggerHook(1);
  
      scene.on("enter", function () {
        $this.find(".swiper-slide").each(function (index, element) {
          tlCarousel.to(
            element,
            1.4,
            { x: 0, opacity: 1, delay: 0.1, ease: Power3.easeOut },
            index * 0.1
          );
        });
      });
  
    });

  };
  function ParallaxScroll() {
    window.$(window).on("load scroll", function () {
      if (window.$(window).width() > 768) {
        var parallaxElement = window.$(".parallax_scroll"),
          parallaxQuantity = parallaxElement.length;
        window.requestAnimationFrame(function () {
          for (var i = 0; i < parallaxQuantity; i++) {
            var currentElement = parallaxElement.eq(i);
            var windowTop = window.$(window).scrollTop(),
              elementTop = currentElement.offset().top,
              elementHeight = currentElement.height(),
              viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
              scrolled = windowTop - elementTop + viewPortHeight;
            currentElement.css({
              transform: "translate3d(0," + scrolled * -0.2 + "px, 0)",
            });
          }
        });
      }
    });
  }

  const toggleCursor = useCallback(() => {
      setCursor(({ active }) => ({ active: !active }));
  }, []);


  return (
    <div className="portfolio-sec" id="portfolio-section">
      <div className="portfolio-title">
        <h3 className="decorator">{decorator}</h3>
        <h1 className="headline">{headline}</h1>
        <p className="subhead">{subhead}</p>
      </div>
      <div className="spacer-3"></div>
      <div
        id="itemsWrapperLinks"
        className="portfolio-wrap sided-grid width-scale-effect"
      >
        <div id="itemsWrapper" className="portfolio">
          <StaticQuery
            query={graphql`
              query MyPortfolioQuery {
                allContentfulComponent(
                  filter: { subsection: { eq: "projects-clients" } }
                  sort: { fields: order }
                ) {
                  nodes {
                    slug
                    headline
                    asset {
                      fluid(maxHeight: 1068, maxWidth: 1706) {
                        src
                      }
                    }
                    subhead
                  }
                }
              }
            `}
            render={(data) =>
              data.allContentfulComponent.nodes.map((portfolio, item) => (
                <div
                  className="item design photo tall disable-drag"
                  key={portfolio.slug}
                  ref={(el) => (portfolioItem.current[item] = el)}
                >
                  <div className="item-parallax">
                    <div className="item-appear">

                      <div className="item-content"
                        onMouseEnter={toggleCursor}
                        onMouseLeave={toggleCursor}
                        role="presentation">
                        <div className="item-wrap-image">
                          <img
                            src={portfolio.asset.fluid.src}
                            className="item-image grid__item-img"
                            alt=""
                          />
                        </div>
                        <img
                          src={portfolio.asset.fluid.src}
                          className="grid__item-img grid__item-img--large"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="item-caption-wrapper parallax_scroll" >
                      <div className="item-caption">
                        <div className="item-title">{portfolio.headline}</div>
                        <div className="item-cat">{portfolio.subhead}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
