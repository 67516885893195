import React, { useLayoutEffect, useContext, useRef, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import { useSiteMetadata } from "../hooks/hero-video-metadata";
import { GlobalContext } from "../providers/GlobalProvider";
import { gsap, Power3, Power2 } from "gsap";

import "../css/hero.css";
import Gif from "../images/HYPED_Hero_Crystal-Mobile.gif";
const { detect } = require("detect-browser");

export default function Hero() {
  const { asset, slug } = useSiteMetadata();
  const heroVideo = useRef();
  const heroRef = useRef();
  const home_hero_container = useRef();
  const heroSelectors = gsap.utils.selector(heroRef);

  const { videoLoaded, setVideoLoaded, preloaderComplete } =
    useContext(GlobalContext);
  const [onLoaded, setOnLoaded] = useState(false);
  const [hideVideo, setHideVideo] = useState(true);
  const [playSafari, setPlaySafari] = useState(false);

  useLayoutEffect(() => {
    function toggleVideoDisplay() {
      if (window.innerWidth >= 992) {
        setHideVideo(false);
        if (!videoLoaded) {
          playVideo();
          heroVideo.current.addEventListener(
            "timeupdate",
            function listener1() {
              pauseAudioAt(heroVideo.current, listener1);
            }
          );
        }
      } else {
        setHideVideo(true);
        showText(0);
      }
    }

    window.addEventListener("resize", toggleVideoDisplay);

    return () => {
      window.removeEventListener("resize", toggleVideoDisplay);
    };
  }, [videoLoaded]);

  useLayoutEffect(() => {
    if (typeof window === "undefined" || !window.document) return;

    if (document.readyState == "complete") {
      var isSafari = false;
      const browser = detect();
      if (browser) {
        if (browser.name.includes("safari")) isSafari = true;
      }

      if (preloaderComplete) {
        if (!videoLoaded) {
          if (window.innerWidth >= 992) {
            playVideo();
            setHideVideo(false);

            heroVideo.current.addEventListener(
              "timeupdate",
              function listener1() {
                pauseAudioAt(heroVideo.current, listener1);
              }
            );
          } else {
            showText(0);
          }
        } else if (isSafari) {
          setPlaySafari(true);
          return videoSafari();
        } else {
          showText(0);
          videoEnded(isSafari);
        }
      }
    }
  }, [preloaderComplete]);

  function playVideo() {
    setVideoLoaded(true);
    heroVideo.current.play();
  }

  useLayoutEffect(() => {
    const browser = detect();
    if (browser && browser.name.includes("safari")) {
      if (playSafari) videoSafari();
    }
  }, [onLoaded]);

  function videoSafari() {
    if (onLoaded) {
      showText(0);
      videoEnded(true);
    }
  }

  function pauseAudioAt(vid, listenerToRemove) {
    if (!vid) {
      return window.removeEventListener("timeupdate", heroVideo.current, true);
    }

    let time = vid.currentTime;
    let seconds = 4.7;
    if (window.innerWidth >= 1700) {
      seconds = 5;
    }
    if (time >= seconds) {
      if (typeof listenerToRemove === "function") {
        vid.removeEventListener("timeupdate", listenerToRemove);
      }
      showText(seconds);
    }
  }
  function showText(seconds) {
    setTimeout(() => {
      var tlHerospan = gsap.timeline();
      tlHerospan.set(heroSelectors("h5.decorator span"), {
        y: 30,
        opacity: 0,
      });
      gsap.to(heroSelectors(".headline span"), {
        duration: 0.8,
        force3D: true,
        y: 0,
        opacity: 1,
        rotation: 0,
        delay: 0.4,
        ease: Power3.easeOut,
      });
      gsap.to(heroSelectors("h5.decorator span"), {
        duration: 0.8,
        force3D: true,
        y: 0,
        opacity: 1,
        rotation: 0,
        delay: 0.4,
        ease: Power2.easeOut,
      });
      gsap.to(heroSelectors(".subhead span"), {
        duration: 0.8,
        force3D: true,
        y: 0,
        opacity: 1,
        rotation: 0,
        delay: 0.8,
        ease: Power2.easeOut,
      });
      gsap.to(heroSelectors(".hero-link span"), {
        duration: 0.8,
        force3D: true,
        y: 0,
        opacity: 1,
        rotation: 0,
        delay: 0.8,
        ease: Power2.easeOut,
      });
    }, seconds);
  }

  function videoEnded(safari) {
    heroVideo.current.currentTime = 10;
    heroVideo.current.play();
    let time = 700;
    if (safari) time = 1;
    setTimeout(() => {
      setHideVideo(false);
    }, time);
  }

  function onLoadedData() {
    setOnLoaded(true);
  }

  function scrollDown() {
    var heroheight = home_hero_container.current.clientHeight;
    window.$("html,body").animate({ scrollTop: heroheight }, 800);
  }
  //if first, autoplay
  return (
    <StaticQuery
      query={graphql`
        query HomepageHero {
          allContentfulComponent(filter: { slug: { eq: "homepage-hero" } }) {
            nodes {
              slug
              decorator
              cta
              headline
              subhead
              asset {
                file {
                  url
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="mobile-flex">
          <div className={`${hideVideo ? "hideVideo" : ""}`}>
            <video
              ref={heroVideo}
              key={slug}
              muted
              className={`hero-video`}
              id="hero-video"
              onEnded={videoEnded}
              onLoadedData={onLoadedData}
            >
              <source src={asset.file.url} type="video/mp4"></source>
            </video>
          </div>
          <div
            className="home-hero-container main-wrapper"
            ref={home_hero_container}
          >
            {data.allContentfulComponent.nodes.map((info) => (
              <div id="hero" key={info.slug} ref={heroRef}>
                <div id="hero-gif">
                  <img src={Gif} type="video/mp4" alt="hero-gif" />
                </div>
                <div id="hero-styles">
                  <div
                    key={info.slug}
                    id="hero-caption"
                    className="home-hero-content"
                  >
                    <div className="inner hero-text">
                      <h5 className="decorator">
                        <span>{info.decorator}</span>
                      </h5>
                      <h1 className="headline dark headline-offset">
                        <span>{info.headline}</span>
                      </h1>
                      <h3 className="subhead dark mb-50">
                        <span>{info.subhead}</span>
                      </h3>
                      <div
                        className="hero-link scroll-down"
                        onClick={scrollDown}
                        aria-hidden="true"
                      >
                        <span>
                          {info.cta} <i className="fa fa-arrow-right"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    />
  );
}
