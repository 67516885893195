import React, { useRef, useState, useContext, useCallback } from "react";
import { graphql, StaticQuery } from "gatsby";
import { useSiteMetadata } from "../hooks/expertise-header-metadata";
import { GlobalContext } from "../providers/GlobalProvider";
import Logo from "../svgs/Logo.svg";

import "../css/expertise.css";


const Expertise = () => {
  const { headline, decorator, subhead } = useSiteMetadata();
  const expertiseLogo = useRef();
  const itemsWrapper = useRef();
  const [ activeitem, setActiveItem ] = useState("");

  const activeLogo = (id) => {
    const logo = expertiseLogo.current;
    const middle = logo.querySelector("#Middle");
    const right = logo.querySelector("#Right");
    const left = logo.querySelector("#Left");

    setActiveItem(id);
    if (window.innerWidth >= 1036) {
      setTimeout(() => {
        if (id === "") {
          middle.style.fill = 'url(#linearGradient-1)';
          right.style.fill = 'url(#linearGradient-1)';
          left.style.fill = 'url(#linearGradient-1)';
        }
        else {
          if (id === "expertise-strategy") {
            middle.style.fill = 'transparent';
            right.style.fill = 'transparent';
            left.style.fill = 'url(#linearGradient-1)';
          } else if (id === "expertise-design") {
            left.style.fill = 'transparent';
            right.style.fill = 'transparent';
            middle.style.fill = 'url(#linearGradient-1)';
          } else if (id === "expertise-analytics") {
            left.style.fill = 'transparent';
            middle.style.fill = 'transparent';
            right.style.fill = 'url(#linearGradient-1)';
          }
        }
      }, 200);
    }
  }

  const { setCursor } = useContext(GlobalContext);

  const toggleCursor = useCallback(() => {
      setCursor(({ active }) => ({ active: !active }));
  }, []);

  return (
    <div className="relative expertise-container flex">
      <div className="expertise-logo" ref={expertiseLogo}>
        <Logo />
      </div>
      <div id="itemsWrapperLinks" className="expertise-wrap" ref={itemsWrapper}>
        <div id="itemsWrapper" >
          <div className="expertise-sec">
            <div className="exp-item branding design tall disable-drag">
              <div className="item-parallax">
                <div className="item-caption-wrapper">
                  <div className="expertise-title">
                    <h3 className="decorator">{decorator}</h3>
                    <h1 className="headline headline-offset">{headline}</h1>
                    <p className="subhead mt-8">{subhead}</p>
                  </div>
                  <div className="spacer-5"></div>
                  <div className="exp-items-wrapper">
                    <StaticQuery
                      query={graphql`
                        {
                          allContentfulComponent(
                            filter: { subsection: { eq: "expertise-items" } }
                            sort: { fields: order }
                          ) {
                            nodes {
                              headline
                              slug
                              icon
                              description {
                                description
                              }
                            }
                          }
                        }
                      `}
                      render={(data) =>
                        data.allContentfulComponent.nodes.map((exp) => (
                          <div
                            className={`expertise-item has-animation ${ activeitem !== "" && activeitem !== exp.slug ? "non-active" : ""}`}
                            data-delay="10"
                            id={exp.slug}
                            key={exp.slug}
                            onMouseEnter={() => {activeLogo(exp.slug); toggleCursor()}}
                            onMouseLeave={() => {activeLogo(""); toggleCursor()}}
                            aria-hidden="true"
                          >
                            <i className={exp.icon} aria-hidden="true"></i>
                            <h3 className="item-title">{exp.headline}</h3>

                            <p className="item-desc">
                              {exp.description ? exp.description.description : ""}
                            </p>
                          </div>
                        ))
                      }
                    ></StaticQuery>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer-200"></div>
    </div>
  );
};

export default Expertise;
